<app-header></app-header>

<section class="hero-wrap hero-wrap-2" style="background-image: url('../../assets/banner.jpg');">
  <div class="overlay"></div>
  <div class="container">
    <div class="row no-gutters slider-text align-items-center justify-content-center">
      <div class="col-md-9 ftco-animate text-center">
        <h1 class="mb-2 bread">About Us</h1>
      </div>
    </div>
  </div>
</section>

<section class="ftco-section">
  <div class="container">
    <div class="row d-flex">
<div class="col-md-12 order-md-last wrap-about align-items-stretch">
  <div class="wrap-about-border ftco-animate">
    <div class="img" style="background-image: url(../../assets/aboutUs.jpg);"></div>
    <div class="text">
      <h3>About Us</h3>
      <p><span style="color: #ff8416; font-weight: 700">MWC LLC</span> is a consulting, research and advisory firm registered on Qatar Financial Center (QFC) platform.
        MWC offers a range of consulting and advisory services, all designed to help your organization and people reach their potential. Whether you're looking for a small tweak or complete overhaul, we have all the solutions. We partner with our clients to transform their business by providing the research, insights and strategic consulting support they need to enhance their business strategies, decisions and results.
      </p>
      <p>We would like to work with:</p>
      <p ><i class="fa fa-check-circle" style="font-size:18px; color: #ff8416"></i><span style="color: #ff8416; font-weight: 700"> Organizations</span> that want to improve business outcomes (productivity, profitability, environment and sustainability)
      </p>
      <p ><i class="fa fa-check-circle" style="font-size:18px; color: #ff8416"></i><span style="color: #ff8416; font-weight: 700"> Professionals and Leaders</span> who want to achieve excellence through customized professional education/ training.
      </p><br>

      <h3>Our Mission</h3>
      <p ><i class="fa fa-check-circle" style="font-size:18px; color: #ff8416"></i> To enable you unlocking your business and human potentials by providing a high quality on-time services in a cost-effective manner.
      </p><br>

      <h3>Our Philosophy</h3>
      <p ><i class="fa fa-check-circle" style="font-size:18px; color: #ff8416"></i> We believe in meaningful relationships, in holding hands and working together as a team with our clients. Our philosophy for business practice is that there is always more to learn and further insight to gain and that solutions and possibilities are endless.

      </p>


    </div>
  </div>
</div>
    </div>
  </div>
</section>

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-industry-focus',
  templateUrl: './industry-focus.component.html',
  styleUrls: ['./industry-focus.component.css']
})
export class IndustryFocusComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

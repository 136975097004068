import { Component, OnInit } from '@angular/core';
import {faCheckCircle, faArrowAltCircleRight} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-esg-and-sustainability-solutions',
  templateUrl: './esg-and-sustainability-solutions.component.html',
  styleUrls: ['./esg-and-sustainability-solutions.component.css']
})
export class EsgAndSustainabilitySolutionsComponent implements OnInit {

  faCheckCircle = faCheckCircle;
  faArrowAltCircleRight = faArrowAltCircleRight;

  constructor() { }

  ngOnInit(): void {
  }

}

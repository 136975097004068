import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {HttpClientModule} from '@angular/common/http';
import { ErrorMsgDTComponent } from './error-msg-dt/error-msg-dt.component';
import { HeaderComponent } from './header/header.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HomepageComponent } from './homepage/homepage.component';
import { FooterComponent } from './footer/footer.component';
import { SliderComponent } from './homepage/slider/slider.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CommonModule} from '@angular/common';
import { MainFeaturesComponent } from './homepage/main-features/main-features.component';
import { ServicesComponent } from './homepage/services/services.component';
import { AboutComponent } from './about/about.component';
import { ServicePageComponent } from './service-page/service-page.component';
import { ContactComponent } from './contact/contact.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { IntroductionComponent } from './about/introduction/introduction.component';
import { IndustryFocusComponent } from './about/industry-focus/industry-focus.component';
import { VisionAndMissionComponent } from './about/vision-and-mission/vision-and-mission.component';
import { StrategicManagementConsultancyComponent } from './list/strategic-management-consultancy/strategic-management-consultancy.component';
import { BusinessDevelopmentAdvisoryComponent } from './list/business-development-advisory/business-development-advisory.component';
import { ProjectManagementOperationalExcellenceComponent } from './list/project-management-operational-excellence/project-management-operational-excellence.component';
import { EnvironmentalSustainabilitySolutionsComponent } from './list/environmental-sustainability-solutions/environmental-sustainability-solutions.component';
import { EsgAndSustainabilitySolutionsComponent } from './list/esg-and-sustainability-solutions/esg-and-sustainability-solutions.component';
import { CorporateTrainingSolutionsComponent } from './list/corporate-training-solutions/corporate-training-solutions.component';





@NgModule({
  declarations: [
    AppComponent,
    ErrorMsgDTComponent,
    HeaderComponent,
    HomepageComponent,
    FooterComponent,
    SliderComponent,
    MainFeaturesComponent,
    ServicesComponent,
    AboutComponent,
    ServicePageComponent,
    ContactComponent,
    IntroductionComponent,
    IndustryFocusComponent,
    VisionAndMissionComponent,
    StrategicManagementConsultancyComponent,
    BusinessDevelopmentAdvisoryComponent,
    ProjectManagementOperationalExcellenceComponent,
    EnvironmentalSustainabilitySolutionsComponent,
    EsgAndSustainabilitySolutionsComponent,
    CorporateTrainingSolutionsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FontAwesomeModule,
    NgbModule,
    CommonModule,
    FormsModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatSnackBarModule,
    ReactiveFormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {

}

import { Component, OnInit } from '@angular/core';
import {faCheckCircle} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-project-management-operational-excellence',
  templateUrl: './project-management-operational-excellence.component.html',
  styleUrls: ['./project-management-operational-excellence.component.css']
})
export class ProjectManagementOperationalExcellenceComponent implements OnInit {

  faCheckCircle = faCheckCircle;

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import * as $ from 'jquery';



@Component({
  selector: 'app-service-page',
  templateUrl: './service-page.component.html',
  styleUrls: ['./service-page.component.css']
})
export class ServicePageComponent implements OnInit {

  type: number;

  constructor(private route: Router) {
    // tslint:disable-next-line:only-arrow-functions

  }



  ngOnInit(): void {
    // $(document).ready(event => {
    //   $('img[usemap]').rwdImageMaps();
    // });
  }



  onMouseEnter(event: any) {
    event.target.click();

    // switch (type) {
    //   case 1:
    //     this.type = 1;
    //     break;
    //   case 2:
    //     this.type = 2;
    //     break;
    //   case 3:
    //     this.type = 3;
    //     break;
    //   case 4:
    //     this.type = 4;
    //     break;
    //   case 5:
    //     this.type = 5;
    //     break;
    //   case 6:
    //     this.type = 6;
    //     break;
    //   default:
    //     this.type = null;
    // }

  }
  onmouseleave(event: any): void {
    event.target.disable = true;
  }

}

<app-header></app-header>
<section class="hero-wrap hero-wrap-2" style="background-image: url('../../assets/banner.jpg');">
  <div class="overlay"></div>
  <div class="container">
    <div class="row no-gutters slider-text align-items-center justify-content-center">
      <div class="col-md-9 ftco-animate text-center">
        <h1 class="mb-2 bread">Project Management & Operational Excellence</h1>
      </div>
    </div>
  </div>
</section>



<section class="ftco-section">
  <div class="container">
    <div class="row d-flex">
      <div class="col-md-12 order-md-last wrap-about align-items-stretch">
        <div class="wrap-about-border ftco-animate">
<!--          <div class="img" style="background-image: url(../../assets/project_mgnt.jpg);"></div>-->
          <img src="../../assets/project_mgnt.jpg" class="img"/>

          <div class="text">
            <h3>Project Management & Operational Excellence</h3>
            <p>We understand the business, technology and cultural needs of project and the organizations. We combine the know-how of experts from operations with professional holistic methodologies and partner with our client in the transformation journey and offer them customized solutions that drive performance and operational efficiency to ensure sustainable business.</p>
            <p>Our <span style="color: #ff8416; font-weight: 700">project management and operational efficiency advisory</span> services cover:</p>

            <p><fa-icon [icon]="faCheckCircle" style="font-size:18px; color: #ff8416"></fa-icon> Project Management Office (PMO), Project governance frameworks</p>
            <p><fa-icon [icon]="faCheckCircle" style="font-size:18px; color: #ff8416"></fa-icon> Improve project management processes, planning and control techniques</p>
            <p><fa-icon [icon]="faCheckCircle" style="font-size:18px; color: #ff8416"></fa-icon> Process Safety Studies for - FERA, QRA, PSSR</p>
            <p><fa-icon [icon]="faCheckCircle" style="font-size:18px; color: #ff8416"></fa-icon> Operational, Maintenance and Reliability Studies</p>
            <p><fa-icon [icon]="faCheckCircle" style="font-size:18px; color: #ff8416"></fa-icon> Process Safety and Risk Management Trainings</p>
            <p><fa-icon [icon]="faCheckCircle" style="font-size:18px; color: #ff8416"></fa-icon> Independent Third Party Design Reviews</p>
            <p><fa-icon [icon]="faCheckCircle" style="font-size:18px; color: #ff8416"></fa-icon> Process Safety Supports by providing  Experienced Chair Person for -HAZOP, HAZID, SIMOPS, PHASER, Value Engineering</p>
            <p><fa-icon [icon]="faCheckCircle" style="font-size:18px; color: #ff8416"></fa-icon> Operational maintenance, Asset integrity and reliability</p>
            <p><fa-icon [icon]="faCheckCircle" style="font-size:18px; color: #ff8416"></fa-icon> Performance Monitoring & Improvement</p>
            <p><fa-icon [icon]="faCheckCircle" style="font-size:18px; color: #ff8416"></fa-icon> Reliability Program Development</p>
            <p><fa-icon [icon]="faCheckCircle" style="font-size:18px; color: #ff8416"></fa-icon> Root Cause Failure Analysis (RCFA)</p>
            <p><fa-icon [icon]="faCheckCircle" style="font-size:18px; color: #ff8416"></fa-icon> Performance benchmarking</p>
            <p><fa-icon [icon]="faCheckCircle" style="font-size:18px; color: #ff8416"></fa-icon> Corrosion Control Solutions</p>
            <p><fa-icon [icon]="faCheckCircle" style="font-size:18px; color: #ff8416"></fa-icon> Energy Management and Efficiency</p>

            <br>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


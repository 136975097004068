<app-header></app-header>

<section class="hero-wrap hero-wrap-2" style="background-image: url('../../assets/banner.jpg');">
  <div class="overlay"></div>
  <div class="container">
    <div class="row no-gutters slider-text align-items-center justify-content-center">
      <div class="col-md-9 ftco-animate text-center">
        <h1 class="mb-2 bread">Vision & Mission</h1>
      </div>
    </div>
  </div>
</section>

<section class="ftco-section">
  <div class="container">
    <div class="row d-flex">
      <div class="col-md-12 order-md-last wrap-about align-items-stretch">
        <div class="wrap-about-border ftco-animate">
<!--          <div class="img" style="background-image: url(../../assets/mission_vision.png);"></div>-->
          <img src="../../assets/mission_vision.png" class="img"/>
          <div class="text">
            <h3>Vision & Mission</h3>


            <h3>Our Mission</h3>
            <p><fa-icon [icon]="faCheckCircle" style="font-size:18px; color: #ff8416"></fa-icon> To enable you unlocking your
              business and human potentials by providing a high quality on-time operational excellence services in a
              cost-effective manner.</p>
            <br>

            <h3>Our Philosophy</h3>
            <p><fa-icon [icon]="faCheckCircle" style="font-size:18px; color: #ff8416"></fa-icon> We believe in strong
              collaboration, in holding hands and working together as a team with our clients. Our philosophy for
              business practice is that there is always more to learn and further insight to gain and that solutions and
              possibilities are endless.
            </p>


          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<app-header></app-header>

<section class="hero-wrap hero-wrap-2" style="background-image: url('../../assets/banner.jpg');">
  <div class="overlay"></div>
  <div class="container">
    <div class="row no-gutters slider-text align-items-center justify-content-center">
      <div class="col-md-9 ftco-animate text-center">
        <h1 class="mb-2 bread">About Us</h1>
      </div>
    </div>
  </div>
</section>

<section class="ftco-section">
  <div class="container">
    <div class="row d-flex">
      <div class="col-md-12 order-md-last wrap-about align-items-stretch">
        <div class="wrap-about-border ftco-animate">

          <div class="img" style="background-image: url(../../assets/about_us.jpg);"></div>
          <div class="text">
            <h3>About Us</h3>
            <p><span style="color: #ff8416; font-weight: 700">MWC LLC</span>
              is a technical and management consulting, research and advisory firm registered on Qatar Financial Center
              (QFC) platform.
              MWC offers a range of technical and management consulting and advisory services in various industry
              sectors, all designed to help your organization and people reach their potential.
              We partner with our clients to transform their business by providing the research, insights and strategic
              consulting support they need to enhance their business strategies, operational sustainability and results.
            </p>
            <p>We offer :</p>
            <h1 style="color: #ff8416; font-weight: 700; font-style: italic; text-align: center">Operational Excellence
              Program Development, Implementation and Tracking</h1>
            <p>We would like to work with:</p>
            <p>
              <fa-icon [icon]="faCheckCircle" style="font-size:18px; color: #ff8416"></fa-icon><span>
              Organizations</span> that want to improve business outcomes (productivity, profitability, environment and
              sustainability)
            </p>
            <p>
              <fa-icon [icon]="faCheckCircle" style="font-size:18px; color: #ff8416"></fa-icon><span>
              Professionals and Leaders</span> who want to achieve excellence through customized professional education/
              training.
            </p>
            <br>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

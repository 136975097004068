<section class="ftco-section">
  <div class="container">
    <div class="row justify-content-center mb-5 pb-2">
      <div class="col-md-8 text-center heading-section ftco-animate">
        <h2 class="mb-4">Our Services</h2>
<!--        <p>We deal with many walk of services. Few as mentioned below</p>-->
      </div>
    </div>
    <div class="row no-gutters" >
      <div class="col-lg-6 d-flex pointOnService" routerLink="/service/strategic-management-consultancy">
        <div class="services-2 text-center ftco-animate">
          <div class="icon mt-2 d-flex justify-content-center align-items-center"><fa-icon [icon]="faDatabase" class="iconColor"></fa-icon> </div><br>
          <div class="text media-body">
            <h3>Strategic Management Consultancy</h3>
          <p>To drive long-term success, you need a clear and actionable business strategy. We offer comprehensive Strategic Management services for your organization focusing on your people and your business: where it is today and where you want it to be tomorrow in achieving outstanding results for your business.
          </p>
          </div>
        </div>
      </div>
      <div class="col-lg-6 d-flex pointOnService" routerLink="/service/business-development-advisory">
        <div class="services-2 text-center ftco-animate">
          <div class="icon mt-2 d-flex justify-content-center align-items-center"><fa-icon [icon]="faAssistiveListeningSystems" class="iconColor"></fa-icon></div><br>
          <div class="text media-body">
            <h3>Business Development Advisory </h3>
          <p>We look at business development as an integrated, cohesive effort that enhances business value, we help our clients to increase revenues, explore and set up new business line and opportunities for investments and attained sustainability by making necessary changes in business models, strategy and operations.
          </p>
          </div>
        </div>
      </div>
      <div class="col-lg-6 d-flex pointOnService" routerLink="/service/project-management-operational-excellence">
        <div class="services-2 text-center ftco-animate">
          <div class="icon mt-2 d-flex justify-content-center align-items-center"><fa-icon [icon]="faUniversity" class="iconColor"></fa-icon> </div><br>
          <div class="text media-body">
            <h3>Project Management and Operational Excellence </h3>
          <p>We understand the business, technology and cultural needs of project and the organizations. We combine the know-how of experts from operations with professional holistic methodologies and partner with our client in the transformation journey and offer them customized solutions that drive performance and operational efficiency to ensure sustainable business.
          </p>
          </div>
        </div>
      </div>
      <div class="col-lg-6 d-flex pointOnService" routerLink="/service/environmental-sustainability-solutions">
        <div class="services-2 text-center ftco-animate">
          <div class="icon mt-2 d-flex justify-content-center align-items-center"><fa-icon [icon]="faSeedling" class="iconColor"></fa-icon> </div><br>
          <div class="text media-body">
            <h3>Environmental Sustainability Solutions</h3>
            <p>As Qatar places climate change at the forefront of its priorities, through our Environmental Consultancy Services, we offer comprehensive range of environment related services, supporting and guiding our clients through environmental impact assessment processes to achieve sustainable environmental goals in their business operations.
            </p>
          </div>
        </div>
      </div>


      <div class="col-lg-6 d-flex pointOnService" routerLink="/service/esg-and-sustainability-solutions">
        <div class="services-2 text-center ftco-animate">
          <div class="icon mt-2 d-flex justify-content-center align-items-center"><fa-icon [icon]="faLeaf" class="iconColor"></fa-icon> </div><br>
          <div class="text media-body">
            <h3>ESG and Sustainability Solutions</h3>
          <p>Companies face a growing need to integrate environmental, social and governance (ESG) principles into enterprise risk management, business strategy, and decision-making. Our ESG and Sustainability Consulting support companies at all stages in their ESG journey, whether that’s taking the first steps, developing an ESG strategy and roadmap, setting a sustainability target or preparing their sustainability report
          </p>
          </div>
        </div>
      </div>


      <div class="col-lg-6 d-flex pointOnService" routerLink="/service/corporate-training-solutions">
        <div class="services-2 text-center ftco-animate">
          <div class="icon mt-2 d-flex justify-content-center align-items-center"><fa-icon [icon]="faChartLine" class="iconColor"></fa-icon> </div><br>
          <div class="text media-body">
            <h3>Business & Professional Training</h3>
            <p>Effective training is the cornerstone of every organization's ability to maximize resource development. Our corporate training solutions provides our clients with effective and efficient means of having training needs identified and appropriate programs designed, developed and delivered.
            </p>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>

import { Component, OnInit } from '@angular/core';
import {faCheckCircle} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-strategic-management-consultancy',
  templateUrl: './strategic-management-consultancy.component.html',
  styleUrls: ['./strategic-management-consultancy.component.css']
})
export class StrategicManagementConsultancyComponent implements OnInit {

  faCheckCircle = faCheckCircle;
  constructor() { }

  ngOnInit(): void {
  }

}

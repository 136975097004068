<app-header></app-header>
<section class="hero-wrap hero-wrap-2" style="background-image: url('../../assets/banner.jpg');">
  <div class="overlay"></div>
  <div class="container">
    <div class="row no-gutters slider-text align-items-center justify-content-center">
      <div class="col-md-9 ftco-animate text-center">
        <h1 class="mb-2 bread">Strategic Management Consultancy</h1>
      </div>
    </div>
  </div>
</section>



<section class="ftco-section">
  <div class="container">
    <div class="row d-flex">
      <div class="col-md-12 order-md-last wrap-about align-items-stretch">
        <div class="wrap-about-border ftco-animate">
          <div class="img" style="background-image: url(../../assets/businessandmanagement.jpg);"></div>
          <div class="text">
            <h3>Strategic Management Consultancy</h3>
            <p>To drive long-term success, you need a clear and actionable business strategy. We offer comprehensive Strategic Management services for your organization focusing on your people and your business: where it is today and where you want it to be tomorrow in achieving outstanding results for your business.</p>
            <p>Our <span style="color: #ff8416; font-weight: 700">Strategic Management Consultancy</span> services include :</p>
            <p><fa-icon [icon]="faCheckCircle" style="font-size:18px; color: #ff8416"></fa-icon> Entering new businesses or markets</p>
            <p><fa-icon [icon]="faCheckCircle" style="font-size:18px; color: #ff8416"></fa-icon> Business growth strategies</p>
            <p><fa-icon [icon]="faCheckCircle" style="font-size:18px; color: #ff8416"></fa-icon> Feasibility Studies</p>
            <p><fa-icon [icon]="faCheckCircle" style="font-size:18px; color: #ff8416"></fa-icon> Strategic portfolio reviews and restructuring</p>
            <p><fa-icon [icon]="faCheckCircle" style="font-size:18px; color: #ff8416"></fa-icon> Strategic operating model</p>
            <p><fa-icon [icon]="faCheckCircle" style="font-size:18px; color: #ff8416"></fa-icon> Performance Management and Improvement</p>
            <p><fa-icon [icon]="faCheckCircle" style="font-size:18px; color: #ff8416"></fa-icon> Business Process Improvement</p>
            <p><fa-icon [icon]="faCheckCircle" style="font-size:18px; color: #ff8416"></fa-icon> Business Continuity Management</p>
            <p><fa-icon [icon]="faCheckCircle" style="font-size:18px; color: #ff8416"></fa-icon> Quality Management issues and its mitigation</p>

            <br>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


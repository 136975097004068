<app-header></app-header>
<section class="hero-wrap hero-wrap-2" style="background-image: url('../../assets/banner.jpg');">
  <div class="overlay"></div>
  <div class="container">
    <div class="row no-gutters slider-text align-items-center justify-content-center">
      <div class="col-md-9 ftco-animate text-center">
        <h1 class="mb-2 bread">Business & Professional Training</h1>
      </div>
    </div>
  </div>
</section>



<section class="ftco-section">
  <div class="container">
    <div class="row d-flex">
      <div class="col-md-12 order-md-last wrap-about align-items-stretch">
        <div class="wrap-about-border ftco-animate">
          <div class="img" style="background-image: url(../../assets/corporatetraining.png);"></div>
          <div class="text">
            <h3>Business & Professional Training</h3>
            <p>Effective training is the cornerstone of every organization's ability to maximize resource development. Our corporate training solutions provides our clients with effective and efficient means of having training needs identified and appropriate programs designed, developed and delivered.</p>
            <p>Our training solutions include:</p>

            <p><fa-icon [icon]="faCheckCircle" style="font-size:18px; color: #ff8416"></fa-icon> Needs assessments, curriculum design and Customized corporate trainings</p>
            <p><fa-icon [icon]="faCheckCircle" style="font-size:18px; color: #ff8416"></fa-icon> Coaching, mentorship and consultation</p>
            <p><fa-icon [icon]="faCheckCircle" style="font-size:18px; color: #ff8416"></fa-icon> Leadership & Executive Development  Programs</p>
            <p><fa-icon [icon]="faCheckCircle" style="font-size:18px; color: #ff8416"></fa-icon> Process Safety and Risk Management Trainings</p>
            <p><fa-icon [icon]="faCheckCircle" style="font-size:18px; color: #ff8416"></fa-icon> Environmental Awareness and EMS (ISO 14001 : 2015) Training</p>
            <p><fa-icon [icon]="faCheckCircle" style="font-size:18px; color: #ff8416"></fa-icon> Quality Management System (ISO 9001:2015) Training</p>
            <p><fa-icon [icon]="faCheckCircle" style="font-size:18px; color: #ff8416"></fa-icon> Occupational Safety and Health (ISO 45001 : 2018) Training</p>
            <p><fa-icon [icon]="faCheckCircle" style="font-size:18px; color: #ff8416"></fa-icon> Workshops & Seminars</p>

            <p>A wealth of industry experienced, professional trainers deliver programs that are developed using most current expertise and technology, and the best practices in all business areas and industry.</p>


            <br>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<app-header></app-header>
<section class="hero-wrap hero-wrap-2" style="background-image: url('../../assets/banner.jpg');">
  <div class="overlay"></div>
  <div class="container">
    <div class="row no-gutters slider-text align-items-center justify-content-center">
      <div class="col-md-9 ftco-animate text-center">
        <h1 class="mb-2 bread">Environmental Sustainability Solutions</h1>
      </div>
    </div>
  </div>
</section>



<section class="ftco-section">
  <div class="container">
    <div class="row d-flex">
      <div class="col-md-12 order-md-last wrap-about align-items-stretch">
        <div class="wrap-about-border ftco-animate">
<!--          <div class="img" style="background-image: url(../../assets/environment.jpg);"></div>-->
          <img src="../../assets/environment.jpg" class="img"/>
          <div class="text">
            <h3>Environmental Sustainability Solutions</h3>
            <p>As Qatar places climate change at the forefront of its priorities, through our Environmental Consultancy Services, we offer comprehensive range of environment related services, supporting and guiding our clients through environmental impact assessment processes to achieve sustainable environmental goals in their business operations.</p>
            <p>We support our clients with our expertise in the following areas related to Environment Permit of Ministry of Environment and Climate Change (MOECC):</p>



            <p><fa-icon [icon]="faCheckCircle" style="font-size:18px; color: #ff8416"></fa-icon> Consent to Operate (CTO) permit application</p>
            <p><fa-icon [icon]="faCheckCircle" style="font-size:18px; color: #ff8416"></fa-icon> Environmental Impact Assessment (EIA) Study</p>
            <p><fa-icon [icon]="faCheckCircle" style="font-size:18px; color: #ff8416"></fa-icon> Preparation and submission of Environmental permit packages and requirements (Arabic & English)</p>
            <p><fa-icon [icon]="faCheckCircle" style="font-size:18px; color: #ff8416"></fa-icon> Environmental Monitoring Plan (EMP)</p>
            <p><fa-icon [icon]="faCheckCircle" style="font-size:18px; color: #ff8416"></fa-icon> Complete Environmental monitoring services</p>
            <p><fa-icon [icon]="faCheckCircle" style="font-size:18px; color: #ff8416"></fa-icon> Environmental Management Plan in line with ISO 14001</p>
            <p><fa-icon [icon]="faCheckCircle" style="font-size:18px; color: #ff8416"></fa-icon> Emergency Response Plan for oil spillage and chemical release</p>
            <p><fa-icon [icon]="faCheckCircle" style="font-size:18px; color: #ff8416"></fa-icon> Waste Management Plan with tracking system</p>
            <p><fa-icon [icon]="faCheckCircle" style="font-size:18px; color: #ff8416"></fa-icon> Facilitation and implementation of Environmental Management Systems (EMS)</p>
            <p><fa-icon [icon]="faCheckCircle" style="font-size:18px; color: #ff8416"></fa-icon> Maintain EMS certifications (ISO- 14001: 2005) - development and Compliance Audit</p>
            <p><fa-icon [icon]="faCheckCircle" style="font-size:18px; color: #ff8416"></fa-icon> EMS Trainings: EIA, Chemical Safety, Air & Water Quality Management, Hazardous Waste & Materials Management, ISO 14001 : 2015 and OHSAS 45001 : 2018</p>



            <br>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<app-header></app-header>

<section class="hero-wrap hero-wrap-2" style="background-image: url('../../assets/banner.jpg');">
  <div class="overlay"></div>
  <div class="container">
    <div class="row no-gutters slider-text align-items-center justify-content-center">
      <div class="col-md-9 ftco-animate text-center">
        <h1 class="mb-2 bread">Industry Focus</h1>

      </div>
    </div>
  </div>
</section>


<section class="ftco-section">

    <img src="../../assets/MWC-Indsutry Focus.png" usemap="#image-map" class="" width="100%" border="0">

  <h1 style="color: #233e62; font-weight: 700; font-style: italic; text-align: center" class="m-2 p-5">
    We will leverage upon our
    core competencies, industry knowledge and experiences,
    translating them into powerful business and learning solutions…

  </h1>

</section>

<section class="copyright">
  <div class="container py-4">
    <div class="row bottom">
      <ul class="col-lg-6 links p-0 ">
        <li><a routerLink="/about/industry-focus" class="text-white">Industry Focus </a></li>
        <li><a routerLink="/services" class="text-white">Services </a></li>
        <li><a routerLink="/contact-us" class="text-white">Contact Us </a></li>
      </ul>
      <div class="col-lg-6 copy-right p-1">
          <p class="text-white text-wrap">Copyright © 2020 All rights reserved by
          <a class="text-white" href="https://www.mwc-consultancy.com/"> MWC Consultancy.</a>
        </p>
      </div>
    </div>
  </div>
</section>




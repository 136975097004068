import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomepageComponent} from './homepage/homepage.component';
import {ServicePageComponent} from './service-page/service-page.component';
import {ContactComponent} from './contact/contact.component';
import {IntroductionComponent} from './about/introduction/introduction.component';
import {VisionAndMissionComponent} from './about/vision-and-mission/vision-and-mission.component';
import {IndustryFocusComponent} from './about/industry-focus/industry-focus.component';
import {StrategicManagementConsultancyComponent} from './list/strategic-management-consultancy/strategic-management-consultancy.component';
import {BusinessDevelopmentAdvisoryComponent} from './list/business-development-advisory/business-development-advisory.component';
import {ProjectManagementOperationalExcellenceComponent} from './list/project-management-operational-excellence/project-management-operational-excellence.component';
import {EnvironmentalSustainabilitySolutionsComponent} from './list/environmental-sustainability-solutions/environmental-sustainability-solutions.component';
import {EsgAndSustainabilitySolutionsComponent} from './list/esg-and-sustainability-solutions/esg-and-sustainability-solutions.component';
import {CorporateTrainingSolutionsComponent} from './list/corporate-training-solutions/corporate-training-solutions.component';




const routes: Routes = [
  {path: '', redirectTo: '/', pathMatch: 'full'},
  {path: '', component: HomepageComponent},
  {path: 'about',
  children: [
  {path: 'introduction', component: IntroductionComponent},
  {path: 'vision-and-mission', component: VisionAndMissionComponent},
  {path: 'industry-focus', component: IndustryFocusComponent},
]},
  {path: 'services', component: ServicePageComponent},
  {path: 'service',
    children: [
      {path: 'strategic-management-consultancy', component: StrategicManagementConsultancyComponent},
      {path: 'business-development-advisory', component: BusinessDevelopmentAdvisoryComponent},
      {path: 'project-management-operational-excellence', component: ProjectManagementOperationalExcellenceComponent},
      {path: 'environmental-sustainability-solutions', component: EnvironmentalSustainabilitySolutionsComponent},
      {path: 'esg-and-sustainability-solutions', component: EsgAndSustainabilitySolutionsComponent},
      {path: 'corporate-training-solutions', component: CorporateTrainingSolutionsComponent}
    ]
  },
  {path: 'contact-us', component: ContactComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';
import {faDatabase, faAssistiveListeningSystems, faUniversity, faSeedling, faLeaf, faChartLine} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {

  faDatabase = faDatabase;
  faAssistiveListeningSystems = faAssistiveListeningSystems;
  faUniversity = faUniversity;
  faSeedling = faSeedling;
  faLeaf = faLeaf;
  faChartLine = faChartLine;

  constructor() { }

  ngOnInit(): void {
  }

}

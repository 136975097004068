<app-header></app-header>
<section class="hero-wrap hero-wrap-2" style="background-image: url('../../assets/banner.jpg');">
  <div class="overlay"></div>
  <div class="container">
    <div class="row no-gutters slider-text align-items-center justify-content-center">
      <div class="col-md-9 ftco-animate text-center">
        <h1 class="mb-2 bread">Our Services </h1>
      </div>
    </div>
  </div>
</section>


<section class="ftco-section p-5">


  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
       viewBox="0 0 1275 1394">
    <image width="1275" height="1394" xlink:href="../../assets/services-with-points.png"></image>
    <a xlink:href="/service/strategic-management-consultancy" target="_blank">
      <rect x="526" y="250" fill="#fff" opacity="0" width="221" height="257" ></rect>
    </a>
    <a xlink:href="/service/business-development-advisory" target="_blank">
    <rect x="818" y="410" fill="#fff" opacity="0" width="198" height="260"></rect>
  </a>
    <a xlink:href="/service/project-management-operational-excellence" target="_blank">
    <rect x="803" y="726" fill="#fff" opacity="0" width="222" height="261"></rect>
  </a>
    <a xlink:href="/service/corporate-training-solutions" target="_blank">
    <rect x="520" y="886" fill="#fff" opacity="0" width="224" height="263"></rect>
  </a>
    <a xlink:href="/service/environmental-sustainability-solutions" target="_blank">
    <rect x="215" y="726" fill="#fff" opacity="0" width="251" height="261"></rect>
  </a>
    <a xlink:href="/service/esg-and-sustainability-solutions" target="_blank">
    <rect x="233" y="409" fill="#fff" opacity="0" width="225" height="261"></rect>
  </a>
  </svg>

  <!-- Image Map Generated by http://www.image-map.net/ -->
<!--  <img src="../../assets/services-with-points.png" usemap="#mapLink" width="100%" border="0"/>-->
<!--  <map name="mapLink">-->
<!--    <area alt="Strategic Management Consultancy" target="_blank" title="Strategic Management Consultancy" href="/service/strategic-management-consultancy" coords="563,250 714,250 790,381 714,509 564,509 490,378 " shape="polygon" >-->
<!--    <area alt="Business Development Advisory" title="Business Development Advisory" href="/service/business-development-advisory" coords="835,411 991,412 1067,542 992,668 840,668 766,540 " shape="polygon" target="_blank">-->
<!--    <area alt="Project Management & Operational Excellence" title="Project Management & Operational Excellence" href="/service/project-management-operational-excellence" coords="839,725 991,727 1067,861 991,987 842,985 768,856 768,856 " shape="polygon" target="_blank">-->
<!--    <area alt="Corporate Training Solutions" title="Corporate Training Solutions" href="/service/corporate-training-solutions" coords="561,885 714,889 789,1020 714,1149 563,1146 490,1016 " shape="polygon" target="_blank">-->
<!--    <area alt="ESG and Sustainability Solutions" title="ESG and Sustainability Solutions" href="/service/esg-and-sustainability-solutions" coords="281,407 437,409 509,540 435,670 286,670 212,538 " shape="polygon" target="_blank">-->
<!--    <area alt="Environmental Sustainability Solutions" title="Environmental Sustainability Solutions" href="/service/environmental-sustainability-solutions" coords="283,727 435,730 509,858 435,989 286,985 212,854 " shape="polygon" target="_blank">-->
<!--  </map>-->
</section>


<!--<section class="ftco-section p-5">-->

<!--  &lt;!&ndash; Image Map Generated by http://www.image-map.net/ &ndash;&gt;-->
<!--    <img src="../../assets/MWC-Services-PNG.png" usemap="#image-map" class="img">-->
<!--     <map name="image-map">-->
<!--      <area target="_blank" (mouseenter)="onMouseEnter($event, 1)" (mouseleave)="onmouseleave($event)" type="button" data-toggle="modal" data-target="#myModal" alt="Strategic Management Consultancy" title="Strategic Management Consultancy" href="/service/strategic-management-consultancy" coords="561,6,714,7,788,139,713,265,562,267,486,136" shape="poly">-->
<!--      <area target="_blank" (mouseenter)="onMouseEnter($event, 2)" (mouseleave)="onmouseleave($event)" type="button" data-toggle="modal" data-target="#myModal" alt="Business Development Advisory" title="Business Development Advisory" href="/service/business-development-advisory" coords="838,168,990,165,1064,296,993,425,842,427,766,297" shape="poly">-->
<!--      <area target="_blank" (mouseenter)="onMouseEnter($event, 3)" (mouseleave)="onmouseleave($event)" type="button" data-toggle="modal" data-target="#myModal" alt="Project Management &amp; Operational Excellence" title="Project Management &amp; Operational Excellence" href="/service/project-management-operational-excellence" coords="838,481,990,481,1064,615,993,743,839,741,764,611" shape="poly">-->
<!--      <area target="_blank" (mouseenter)="onMouseEnter($event, 4)" (mouseleave)="onmouseleave($event)" type="button" data-toggle="modal" data-target="#myModal" alt="Environmental sustainability solutions" title="Environmental sustainability solutions" href="/service/environmental-sustainability-solutions" coords="283,485,434,483,508,612,434,745,283,741,208,612" shape="poly">-->
<!--      <area target="_blank" (mouseenter)="onMouseEnter($event, 5)" (mouseleave)="onmouseleave($event)" type="button" data-toggle="modal" data-target="#myModal" alt="ESG and Sustainability Solutions" title=" ESG and Sustainability Solutions" href="/service/esg-and-sustainability-solutions" coords="283,165,435,167,509,299,435,427,285,426,210,297" shape="poly">-->
<!--      <area target="_blank" (mouseenter)="onMouseEnter($event, 6)" (mouseleave)="onmouseleave($event)" type="button" data-toggle="modal" data-target="#myModal" alt="Corporate Training Solutions" title="Corporate Training Solutions" href="/service/corporate-training-solutions" coords="560,644,713,645,789,777,714,905,560,903,487,774" shape="poly">-->
<!--    </map>-->


<!--  <div class="modal" id="myModal">-->
<!--    <div class="modal-dialog modal-dialog-centered">-->
<!--      <div class="modal-content modalBg" [ngSwitch]="type">-->
<!--        &lt;!&ndash;        Strategic Management Consultancy points in modal&ndash;&gt;-->
<!--        <div class="modal-body modelColor" *ngSwitchCase="1">-->
<!--          <p><i class="fa fa-check-circle" style="font-size:18px; color: #ff8416"></i> Entering new businesses or markets</p>-->
<!--          <p><i class="fa fa-check-circle" style="font-size:18px; color: #ff8416"></i> Corporate / Business Unit Strategy</p>-->
<!--          <p><i class="fa fa-check-circle" style="font-size:18px; color: #ff8416"></i> Business restructuring /Strategic Operating Model</p>-->
<!--          <p><i class="fa fa-check-circle" style="font-size:18px; color: #ff8416"></i> Organizational and Financial Planning</p>-->
<!--          <p><i class="fa fa-check-circle" style="font-size:18px; color: #ff8416"></i> Feasibility Studies</p>-->
<!--        </div>-->

<!--        &lt;!&ndash;       Business Development Advisory points in modal&ndash;&gt;-->
<!--        <div class="modal-body modelColor" *ngSwitchCase="2">-->
<!--          <p><i class="fa fa-check-circle" style="font-size:18px; color: #ff8416"></i> New business venture planning, startups, investments opportunities</p>-->
<!--          <p><i class="fa fa-check-circle" style="font-size:18px; color: #ff8416"></i> Market Feasibility/ Market & Trend Research</p>-->
<!--          <p><i class="fa fa-check-circle" style="font-size:18px; color: #ff8416"></i> Market development for international organization and services</p>-->
<!--        </div>-->

<!--        &lt;!&ndash;        Project Management &amp; Operational Excellence points in modal&ndash;&gt;-->
<!--        <div class="modal-body modelColor" *ngSwitchCase="3">-->
<!--          <p><i class="fa fa-check-circle" style="font-size:18px; color: #ff8416"></i> Project management processes and planning</p>-->
<!--          <p><i class="fa fa-check-circle" style="font-size:18px; color: #ff8416"></i> Process safety, Operational maintenance, Asset integrity and reliability</p>-->
<!--          <p><i class="fa fa-check-circle" style="font-size:18px; color: #ff8416"></i> Performance Monitoring & Improvement</p>-->
<!--          <p><i class="fa fa-check-circle" style="font-size:18px; color: #ff8416"></i> Energy management and Efficiency</p>-->
<!--        </div>-->

<!--        &lt;!&ndash;       Environmental sustainability solutions points in modal&ndash;&gt;-->
<!--        <div class="modal-body modelColor" *ngSwitchCase="4">-->
<!--          <p><i class="fa fa-check-circle" style="font-size:18px; color: #ff8416"></i> Environmental Impact Assessment and Monitoring</p>-->
<!--          <p><i class="fa fa-check-circle" style="font-size:18px; color: #ff8416"></i> Environmental Permit Packages</p>-->
<!--          <p><i class="fa fa-check-circle" style="font-size:18px; color: #ff8416"></i> Environmental Management System</p>-->
<!--          <p><i class="fa fa-check-circle" style="font-size:18px; color: #ff8416"></i> EMS Audit & Compliance</p>-->
<!--          <p><i class="fa fa-check-circle" style="font-size:18px; color: #ff8416"></i> Waste Management</p>-->
<!--        </div>-->

<!--        &lt;!&ndash;       ESG and Sustainability Solutions points in modal&ndash;&gt;-->
<!--        <div class="modal-body modelColor" *ngSwitchCase="5">-->
<!--          <p><i class="fa fa-check-circle" style="font-size:18px; color: #ff8416"></i> Defining Sustainability Strategy and Roadmap</p>-->
<!--          <p><i class="fa fa-check-circle" style="font-size:18px; color: #ff8416"></i> ESG Metrics, Benchmarking and Reporting</p>-->
<!--          <p><i class="fa fa-check-circle" style="font-size:18px; color: #ff8416"></i> Corporate Compliance & Risk Assessment</p>-->
<!--        </div>-->

<!--        &lt;!&ndash;        Corporate Training Solutions points in modal&ndash;&gt;-->
<!--        <div class="modal-body modelColor" *ngSwitchCase="6">-->
<!--          <p><i class="fa fa-check-circle" style="font-size:18px; color: #ff8416"></i> Customized corporate trainings</p>-->
<!--          <p><i class="fa fa-check-circle" style="font-size:18px; color: #ff8416"></i> Coaching, mentorship and consultation</p>-->
<!--          <p><i class="fa fa-check-circle" style="font-size:18px; color: #ff8416"></i> Leadership & Executive Development Programs</p>-->
<!--          <p><i class="fa fa-check-circle" style="font-size:18px; color: #ff8416"></i> Process Safety and Risk Management Trainings</p>-->
<!--          <p><i class="fa fa-check-circle" style="font-size:18px; color: #ff8416"></i> QMS and EMS Trainings</p>-->
<!--        </div>-->

<!--      </div>-->
<!--    </div>-->
<!--  </div>-->


<!--</section>-->

